import React from "react";
import { getNewsDetail } from "services";
import { BaseLayout, Loading } from "components";
import styles from "./styles.module.scss";
import queryString from 'query-string';

class GuideDetail extends React.PureComponent {
  state = {
    loading: true
  };
  componentDidMount() {
    const { history, match } = this.props;
    const { params } = match;
    if (!params.id || !Number.isInteger(parseInt(params.id))) {
      history.goBack();
    }
    this.getDetail();
    this.baiduTongji();
  }

  baiduTongji() {
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?b9f902cd899fc926c2a55346149c216d";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }

  getDetail = async () => {
    const { match } = this.props;
    const { params } = match;
    const res = await getNewsDetail(params.id);
    if (res.success && res.ch_msg.length > 0) {
      this.setState({
        loading: false,
        ...res.ch_msg[0]
      }, () => {

        // 继续跳转带app 参数下去
        const __params = queryString.parse(window.location.search) || {};
        const _a = document.querySelectorAll('a[href]') || [];
        _a.forEach(element => {
          element.href = queryString.stringifyUrl({ url: element.href, query: { ...__params } });
        });
      });
    }
  };



  render() {
    const { loading, title, content } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <div className="plane full-screen">
            <h2 className="page-title">{title}</h2>
            {/* <p className={styles.time}>{ctime}</p> */}
            <div className={styles.detail}>
              <div
                className="page-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        )}
      </BaseLayout>
    );
  }
}
export default GuideDetail;
