import React from "react";
import { getNewsDetail } from "services";
import { BaseLayout, Loading } from "components";
import styles from "./styles.module.scss";
import queryString from "query-string";

class GuideDetail extends React.PureComponent {
  state = {
    loading: true,
  };
  componentDidMount() {
    document.title = "充币指南";
    const id = process.env.BUILD_ENV === "uat" ? 434386 : 679265;
    this.getDetail(id);
    this.baiduTongji();
  }

  baiduTongji() {
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?b9f902cd899fc926c2a55346149c216d";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }

  getDetail = async (id) => {
    const res = await getNewsDetail(id);
    if (res.success && res.ch_msg.length > 0) {
      this.setState({
        loading: false,
        ...res.ch_msg[0],
      });
    }
  };

  render() {
    const { loading, title, content } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <div className="plane full-screen">
            <h2 className="page-title">{title}</h2>
            {/* <p className={styles.time}>{ctime}</p> */}
            <div className={styles.detail}>
              <div
                className="page-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        )}
      </BaseLayout>
    );
  }
}
export default GuideDetail;
