import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default function Footer({ title, className = "" }) {
  return (
    <div className={classnames("tips", className)}>
      © Copyrights © {new Date().getFullYear()} {title} 版权所有{" "}
    </div>
  );
}
Footer.defaultProps = {
  title: "BT Global",
};
Footer.propTypes = {
  title: PropTypes.string,
};
