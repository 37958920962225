import React from "react";
import classnames from "classnames";
import { Modal, Toast } from "antd-mobile";
import { BaseLayout, Footer } from "components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isIOS, handleDownloadProcess, excuteDownload } from "utils";
import fetchJsonp from "fetch-jsonp";
import { linkConfig, webCfg } from "config";
import styles from "./styles.module.scss";
import queryString from "query-string";
const Alert = Modal.alert;
const HiddenDownloadInfo = () => (
  <div id="download-info" style={{ display: "none" }}>
    <span>pooling download urls</span>
    {linkConfig.download.androidUrl.btcc.map((url, i) => (
      <span id={`url-${i}`} key={i}>
        {url}
      </span>
    ))}
    <span id={"backup-url"}>{linkConfig.download.androidUrl.btcc_backup}</span>
  </div>
);
class Download extends React.PureComponent {
  state = {
    androidUrl: linkConfig.download.androidUrl["btcc"],
    androidUrlBackup: linkConfig.download.androidUrl["btcc_backup"] || "",
    loading: false,
    title: "立即下载",
    slogan: "BT Global",
    platform: "btcc",
    detail: "https://www.woyaotf.cn/app_merge/BTHQ",
    copy: false,
  };

  componentDidMount() {
    if (window.location.search) {
      const params = queryString.parse(window.location.search);
      if (
        params.platform &&
        Object.keys(linkConfig.download.ios).includes(params.platform)
      ) {
        this.setState({
          slogan: params.title,
          platform: params.platform,
          detail: `${this.state.detail}${window.location.search}`,
        });
      }
    } else {
      // this.getUrl();
    }
  }
  getUrl = async () => {
    const res = await fetchJsonp(linkConfig.download.android);
    const ret = await res.json();
    if (ret.code === "success") {
      this.setState({
        androidUrl: ret.data.url,
      });
    }
  };
  startDownload = async () => {
    this.setState({
      loading: true,
      title: isIOS() ? "立即下载" : "获取中...",
    });
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeixinBrowser = !!ua.match(/MicroMessenger/i);
    const isWhiteBrowser = !!ua.match(/MQQBrowser|Safari|UCBrowser/i);
    if (!isWhiteBrowser && isIOS() && !isWeixinBrowser) {
      this.setState({
        copy: true,
      });
      Alert("请在Safair浏览器中打开此页面并再次点击“立即下载”按钮", "", [
        { text: "取消" },
        {
          text: "复制链接",
          onPress: this.copy,
        },
      ]);
      return;
    }
    let urls = this.state.androidUrl;
    let backUrl = this.state.androidUrlBackup;
    try {
      await handleDownloadProcess(urls);
    } catch (e) {
      backUrl && excuteDownload(backUrl);
    } finally {
      this.setState({
        loading: false,
        title: "立即下载",
      });
    }
  };
  copy = () => {
    this.$copy.current && this.$copy.current.click();
    Toast.success("复制成功！", 1);
  };
  hideDetail = () => {
    this.setState({
      step: false,
    });
  };
  render() {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeixinBrowser = !!ua.match(/MicroMessenger/i);
    return (
      <BaseLayout>
        <div
          className={classnames("full-screen", styles.download, {
            [styles.fixed_scroll]: this.state.step || this.state.detail,
          })}
        >
          {isWeixinBrowser ? (
            <div
              className={classnames(styles.shadow, {
                [styles.android]: !isIOS(),
              })}
            />
          ) : null}
          <h2>合约交易 触手可及</h2>
          <p className={styles.summary}>全球著名的差价合约交易平台</p>

          <div
            className={styles.animate}
            style={{
              backgroundImage: `url("/download/${this.state.platform}.png")`,
            }}
          />
          <h3>{this.state.slogan} App下载 </h3>
          <p className={styles.strength}>
            <span>安全</span>
            <span>稳定</span>
            <span>智能</span>
            <span>服务</span>
          </p>
          <div className={styles.download_wrapper}>
            <div className="flex-container flex-space-between layout-vertical">
              {isIOS() ? (
                <React.Fragment>
                  <div className={styles.box}>
                    <a
                      href={linkConfig.download.ios.bt}
                      className={classnames(
                        styles.download_btn_ios,
                        styles.download_btn_appStore,
                        {
                          [styles.loading]: this.state.loading,
                        }
                      )}
                    >
                      <span className={styles.ios_icon} />
                      App Store
                    </a>
                  </div>

                  <a
                    href={linkConfig.h5transaction}
                    className={classnames(styles.download_btn_page, {
                      [styles.loading]: this.state.loading,
                    })}
                  >
                    网页交易无需下载
                    <span className="gestrue_icon" />
                  </a>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div
                    onClick={this.startDownload}
                    className={classnames(
                      "flex-block",
                      styles.download_btn_and
                    )}
                  >
                    {this.state.title}
                    <span className="label" />
                  </div>
                  <a
                    href={linkConfig.h5transaction}
                    className={classnames(
                      "flex-container flex-center layout-vertical",
                      styles.download_btn_page,
                      {
                        [styles.loading]: this.state.loading,
                      }
                    )}
                  >
                    网页交易无需下载
                    <span className="gestrue_icon" />
                    {/* <small className="block-show">适合所有 Apple ID</small> */}
                  </a>
                </React.Fragment>
              )}
            </div>
          </div>

          {this.state.copy ? (
            <CopyToClipboard
              className="visible-hide"
              text={`${window.location.origin}/download${
                window.location.search
              }`}
            >
              <i ref={this.$copy} />
            </CopyToClipboard>
          ) : null}
          <HiddenDownloadInfo />
          <Footer title={webCfg[this.state.platform].copyright} />
        </div>
      </BaseLayout>
    );
  }
}

export default Download;
