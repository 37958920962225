import React from "react";
import { withRouter } from "react-router-dom";
import { BaseLayout, Loading } from "components";
import { getNews } from "services";
import "react-perfect-scrollbar/dist/css/styles.css";
import styles from "./styles.module.scss";
import { isNative } from 'utils';

const env = process.env.BUILD_ENV || '';

class GuideList extends React.PureComponent {
  state = {
    askList: [],
    loading: true,
    askListData: [],
    count: 5,
  };
  componentDidMount() {
    document.title = "新手指南";
    const id = env === "uat" ? 264 : 721; //  常见问题


    this.getList(id);
  }

  getList = async id => {
    try {
      const res = await getNews(id, { limit: 200 });
      if (res.success) {
        this.setState({
          loading: false,
          askList: [...res.ch_msg],
          askListData: [...res.ch_msg].slice(0, 5) // 默认显示前5条

        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 返回
  onBack = () => {
    window.history.back();
  }

  // 换一批
  onRefresh = () => {
    const { askList, count } = this.state;
    const list = askList.slice(count, count + 5);
    if (list[0]) {
      this.setState({
        askListData: list,
        count: count + 5
      })
    } else {
      this.setState({
        count: 5,
        askListData: askList.slice(0, 5),
      })
    }
  }

  // 跳转指定地址
  goHref = (link) => {
    const { history } = this.props;
    history.push(link);
  }
  render() {
    const { loading, askListData } = this.state;


    // 头部数据配置
    const dataConfig = [
      {
        title: '快速了解我们',
        tips: '交易平台介绍  安全性  资质',
        link: `/detail_new/${env === "uat" ? 147142 : 678051}`,
      },
      {
        title: '如何进行杠杆交易',
        tips: '怎么操作多空单  计算盈亏  怎么计算费率',
        link: `/detail_new/${env === "uat" ? 147143 : 678058}`,
      },
      {
        title: '如何存款取款',
        tips: '有哪些存款方式  平台资金如何保障',
        link: `/detail_new/${env === "uat" ? 147144 : 678061}`,
      },
    ]


    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
            <section className={styles.section}>
              <div className={styles.banner}>
                {isNative ? null : <div className={styles.back} onClick={this.onBack} />}
                <div className={styles.guide}>新手指南</div>
              </div>
              <div className={styles.scroll_container}>
                {
                  dataConfig.map((item, index) => (
                    <div key={index} className={styles.item} onClick={() => this.goHref(item.link)}>
                      <div className={styles.item_left}>
                        <div className={styles.title}>{item.title}</div>
                        <div className={styles.tips}>{item.tips}</div>
                      </div>
                      <div className={styles.item_icon} />
                    </div>
                  ))
                }

                <div className={styles.question}>
                  <div className={styles.title}>常见问题</div>
                  <div className={styles.cut} onClick={this.onRefresh}>
                    <div className={styles.icon_refresh} />
                      换一批
                    </div>
                </div>
              </div>
              <div className={styles.askList}>
                {
                  askListData.map((item, index) => (
                    <div key={index} className={styles.askList_item} onClick={() => this.goHref(`/detail_new/${item.id}`)}>
                      <div className={styles.askList_title}>{item.title}</div>
                      <div className={styles.askList_arrow} />
                    </div>
                  ))
                }
              </div>
            </section>
          )}
      </BaseLayout>
    );
  }
}

export default withRouter(GuideList);
