import React from "react";
import { withRouter } from "react-router-dom";
import { Tabs } from "antd-mobile";
import { BaseLayout, Loading } from "components";
import { getNews } from "services";
import to from "utils/to";
import styles from "./styles.module.scss";
import { isNative, nativeHref } from "utils";
import { hkHelpCenterIds } from "config";

const env = process.env.BUILD_ENV || '';

class GuideList extends React.PureComponent {
  state = {
    askList: [],
    loading: true,
    askListData: [],
    count: 5,
    extendIds: [], // 展开的分类id集合
    extendIdsMap: new Map(), // 展开的分类id与数据
    contentMap: new Map(), // 内容数据
  };
  componentDidMount() {
    document.title = "新手指南";
    this.getList();
  }

   // 获取列表
   getList = async () => {
    for (let i = 0; i < hkHelpCenterIds.length; i++) {
      const item = hkHelpCenterIds[i];
      await this.getChildList(item.id);
    }

    this.setState({
      loading: false,
    });
  };

  // 获取子分类
  getChildList = async (id) => {
    if (this.state.contentMap.has(id)) {
      return;
    }
    const [err, res] = await to(getNews(id, { limit: 100 }));
    if (err) {
      console.log(err);
      return;
    }
    if (res.code === 200 && Array.isArray(res.ch_msg)) {
      const contentMap = this.state.contentMap;
      contentMap.set(id, res.ch_msg);
      this.setState({
        contentMap,
      });
    }
  };

  // 返回
  onBack = () => {
    window.history.back();
  };

  // 到在线客服
  goCustomer = () => {
    if (isNative) {
      nativeHref('customer');
    } else {
      const a = document.createElement('a');
      a.href = 'https://btcccfd.kf5.com/kchat/38726?from=%E5%9C%A8%E7%BA%BF%E6%94%AF%E6%8C%81&group=0#/chatbox';
      a.target = '_blank';
      a.click();
    }
  }

  // 跳转指定地址
  goHref = (link) => {
    const { history } = this.props;
    history.push(link);
  };
  renderContent = (tab) => {
    const id = tab.id;
    const content = this.state.contentMap.get(id);

    return content && content.length > 0 ? (
      <div>
        {content.map((item, index) => (
          <div
            key={index}
            className={styles.item_header}
            onClick={() => this.goHref(`/detail_new/${item.id}`)}
          >
            <div className={styles.item_header_title}>{item.title}</div>
            <div
              className={`${styles.item_header_arrow} ${
                this.state.extendIds.includes(item.id) ? "down" : ""
              }`}
            />
          </div>
        ))}
      </div>
    ) : (
      <div className={styles.empty}>暂无数据</div>
    );
  };
  render() {
    const { loading } = this.state;

    const tabs2 = hkHelpCenterIds.map((item) => {
      return { title: item.title, id: item.id };
    });

    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
          <section className={styles.wrapper}>
            <header className={styles.header}>
              {isNative ? null : (
                <i className="icon icon-back" onClick={this.onBack} />
              )}
              {/* <div className="title">帮助中心</div>
              <div className="desc">Help Center</div> */}
            </header>
            {
              <div className={styles.scroll_container}>
                <Tabs
                  tabs={tabs2}
                  renderTabBar={(props) => (
                    <Tabs.DefaultTabBar {...props} page={process.env.BUILD_ENV === "uat" ? 3: 3.5} />
                  )}
                  onTabClick={(tab) => {
                    this.setState({
                      extendIds: [...this.state.extendIds, tab.id],
                    });
                  }}
                >
                  {this.renderContent}
                </Tabs>
              </div>
            }

            <footer className={styles.footer} onClick={this.goCustomer}>
              <div className={styles.icon_kefu} />
              <div>在线客服</div>
            </footer> 
          </section>
        )}
      </BaseLayout>
    );
  }
}

export default withRouter(GuideList);
