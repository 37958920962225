import React from "react";
import { getNewsDetail } from "services";
import { BaseLayout, Loading } from "components";
import styles from "./styles.module.scss";
import { isNative, nativeHref } from 'utils';

class GuideDetail extends React.PureComponent {
  state = {
    loading: true,
    active: '',
  };
  componentDidMount() {
    const { history, match } = this.props;
    const { params } = match;
    document.title = "问题详情";

    if (!params.id || !Number.isInteger(parseInt(params.id))) {
      history.goBack();
    }
    this.getDetail();
  }
  getDetail = async () => {
    const { match } = this.props;
    const { params } = match;
    const res = await getNewsDetail(params.id);
    if (res.success) {
      this.setState({
        loading: false,
        ...res.ch_msg[0]
      });
    }
  };

  // 返回
  onBack = () => {
    window.history.back();
  }

  // 有用没用切换
  onActive = (active) => {
    this.setState({
      active
    })
  }

  render() {
    const { loading, title, content, active } = this.state;
    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
            <section className={styles.detail_new}>

              {
                isNative ? null :
                  <header className={styles.header}>
                    <div className={styles.back} onClick={this.onBack} />
                    <div className={styles.title}>问题详情</div>
                  </header>
              }
              {title && <div className={styles.content}>
                <div className={styles.detail}>
                  <div className={styles.detail_header}>
                    <span className={styles.icon}>Q:</span> <span className={styles.title}>{title}</span>
                  </div>
                  <div
                    className={styles.detail_content}
                    dangerouslySetInnerHTML={{ __html: content }}
                  >
                  </div>
                  <div className={styles.detail_footer}>
                    <span  >是否对您有帮助：</span>
                    <div className={styles.agreeOrNo}>
                      <div onClick={() => this.onActive('0')} className={`${styles.agree} ${active === '0' ? styles.active : null}`}>
                        <div className={styles.agree_item}>
                          <div className={styles.agree_icon} />
                            有用
                        </div>
                      </div>
                      <div onClick={() => this.onActive('1')} className={`${styles.notAgree} ${active === '1' ? styles.active : null}`}>
                        <div className={styles.agree_item}>
                          <div className={styles.agree_icon} />
                          没用
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

              }
            </section>
          )}
      </BaseLayout>
    );
  }
}
export default GuideDetail;
